<template>
  <Route
    class="CardPost"
    :to="{ name: 'Post', params: { slug: post.slug } }">
    <div class="details wrap">
      <Tag>Naujiena</Tag>
      <div class="title">
        {{ post.title }}
      </div>
      <div class="date">
        <Icon name="calendar" />
        {{ new Date(post.date) | date }}
      </div>
    </div>
    <div class="preview">
      <Pic
        :src="post.image.url" />
    </div>
  </Route>
</template>

<script>
export default {
  props: {
    post: Object,
  },
}
</script>

<style lang="scss">
.CardPost {
  background: $white;
  border-bottom: unset;
  border-radius: $radius 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .wrap {
    padding: 2rem 3rem 0;
  }

  .details {
    height: 20rem;
    white-space: normal;

    > * {
      margin-bottom: 1rem;
    }

    .title {
      font-size: $h4;
      font-weight: $bold;
    }

    .date {
      color: $blue;
    }
  }

  .preview {
    border-radius: $radius 0;
    height: 25rem;
    // height: 25rem;
    // opacity: 0.66;
    overflow: hidden;
    position: relative;
    width: auto;

    img {
      filter: brightness(0.8);
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  // width: 20rem;
  // min-width: 90vw;
  // @include md {
  //   min-width: 60vw;
  // }
  &.fullSize {
    flex-direction: row;
  }
}
</style>
