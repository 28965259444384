<template>
  <div id="News">
    <Hero src="../assets/solar-plants/hero.jpg">
      <h1>Naujienos</h1>
    </Hero>
    <Wrap class="lg">
      <Empty v-if="isLoading">
        <Loader />
      </Empty>
      <Sect v-else class="posts">
        <Row>
          <Column
            v-for="(post, index) in posts"
            :key="index"
            :lg="4">
            <CardPost
              :post="post" />
          </Column>
        </Row>
      </Sect>
    </Wrap>
  </div>
</template>

<script>
import CardPost from './components/CardPost'
import Hero from './components/landing/Hero'
import Meta from './mixins/Meta'
import axios from 'axios'

export default {
  mixins: [
    Meta(
      {
        inner: 'Naujienos',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: {
    CardPost,
    Hero,
  },
  data() {
    return {
      posts: null,
      isLoading: false,
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      const response = await axios
        .get('https://api.suninvestmentgroup.com/postSb?_sort=date:DESC')

      this.posts = response.data.slice(0, 10)
    } catch (error) {
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="scss">
#News {
  background: $white-ter;

  .Hero {
    @include roundSvg('../assets/ellipse.svg');

    h1 {
      @include dotBefore(8rem);
    }
  }

  .CardPost {
    margin: 2rem;
  }
}

</style>
